<template>
  <div class="body-bg">
    <van-nav-bar
      title="接单大厅"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <img src="@/assets/img/index-o.png" style="width: 16px;height:16px" />
      </template>
    </van-nav-bar>

    <div class="order-list-box">
      <van-tabs
        sticky
        active="0"
        color="#F3C301"
        title-active-color="#F3C301"
        title-inactive-color="#B3B3B3"
        line-width="40rpx"
        line-height="4rpx"
        @change="changeServiceType"
      >
        <van-tab title="全部" name="0">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="loadData(0)"
          >
            <ul>
              <li
                class="order-item-container"
                v-for="(item, index) in orderList"
                :key="index"
                @click="toAcceptOrder(item.id)"
              >
                <div class="order-item-box">
                  <div class="order-item-img">
                    <img
                      :src="'https://jsagency.cn/'+item.thumb_banner"
                      v-if="item.zh_name != '综合服务'"
                    />
                    <img
                      class="zh-img"
                      :src="'https://jsagency.cn/'+item.thumb_banner"
                      v-else
                    />
                  </div>
                  <div class="order-item-content">
                    <div class="header-box">
                      <div class="header">
                        <span class="title">{{ item.zh_name }}</span>
                        <span class="date spaceL">{{ item.create_at }}</span>
                      </div>
                      
                      <!--
                      <van-tag
                        v-if="item.user_type == 1"
                        round
                        plain
                        text-color="#F1BD1E"
                        color="#FFF9E6"
                        >个人</van-tag
                      >
                      <van-tag
                        v-else-if="
                          item.user_type == 2"
                        round
                        plain
                        text-color="#1EAEF1"
                        color="#E5F7FF"
                        >企业</van-tag
                      >
                      -->
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.remarks}}
                        </p>
                      </div>
                    </div>

                    <div class="item">
                      <div class="title spaceR">
                        <p>用工时长:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{ item.long_time }}
                        </p>
                      </div>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>数量:</p>
                      </div>
                      <div class="text spaceL">
                        <p>{{ item.order_num }}</p>
                      </div>
                    </div>

                    <div class="item">
                      <div class="title spaceR">
                        <p>位置:</p>
                      </div>
                      <div class="text spaceL">
                        <p>{{ item.order_area }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="order-item-footer">
                  <van-cell-group :border="true">
                    <van-cell class="van-cell-raduis-bottom">
                      <!-- 使用 title 插槽来自定义标题 -->
                      <template #title>
                        <span class="">代办费:</span>
                        <span class="price spaceL"
                          >￥{{ item.real_price }}</span
                        >
                        <van-button
                          style="width: 60px;float:right"
                          round
                          size="mini"
                          type="info"
                          color="#F3C301"
                          @click="toAcceptOrder(item.id)"
                          >去接单</van-button
                        >
                      </template>
                    </van-cell>
                  </van-cell-group>
                </div>
              </li>
            </ul>
          </van-list>
        </van-tab>
        <div v-for="item in serviceList" :key="item.index">
          <van-tab :title="item.zh_name" :name="item.id">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="loadData(item.id)"
            >
              <ul>
                <li
                  class="order-item-container"
                  v-for="(item, index) in orderList"
                  :key="index"
                  @click="toAcceptOrder(item.id)"
                >
                  <div class="order-item-box">
                    <div class="order-item-img">
                      <img
                        :src="'https://jsagency.cn/'+item.thumb_banner"
                        v-if="item.zh_name != '综合服务'"
                      />
                      <img
                        class="zh-img"
                        :src="'https://jsagency.cn/'+item.thumb_banner"
                        v-else
                      />
                    </div>
                    <div class="order-item-content">
                      <div class="header-box">
                        <div class="header">
                          <span class="title">{{ item.zh_name }}</span>
                          <span class="date spaceL">{{ item.create_at }}</span>
                        </div>
                        <!--
                        <van-tag
                          v-if="
                            item.user_type == 1
                          "
                          round
                          plain
                          text-color="#F1BD1E"
                          color="#FFF9E6"
                          >个人</van-tag
                        >
                        <van-tag
                          v-else-if="
                            item.user_type == 2
                          "
                          round
                          plain
                          text-color="#1EAEF1"
                          color="#E5F7FF"
                          >企业</van-tag
                        >
                        -->
                      </div>
                      <div class="item">
                        <div class="title spaceR">
                          <p>服务需求:</p>
                        </div>
                        <div class="text spaceL">
                          <p>
                            {{item.remarks}}
                          </p>
                        </div>
                      </div>

                      <div class="item">
                        <div class="title spaceR">
                          <p>用工时长:</p>
                        </div>
                        <div class="text spaceL">
                          <p>
                            {{ item.long_time }}
                          </p>
                        </div>
                      </div>

                      <div class="item">
                        <div class="title spaceR">
                          <p>数量:</p>
                        </div>
                        <div class="text spaceL">
                          <p>{{ item.order_num }}</p>
                        </div>
                      </div>
                      <div class="item">
                        <div class="title spaceR">
                          <p>位置:</p>
                        </div>
                        <div class="text spaceL">
                          <p>{{ item.order_area }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="order-item-footer">
                    <van-cell-group :border="true">
                      <van-cell class="van-cell-raduis-bottom">
                        <!-- 使用 title 插槽来自定义标题 -->
                        <template #title>
                          <span class="">代办费:</span>
                          <span class="price spaceL"
                            >￥{{ item.real_price }}</span
                          >
                          <van-button
                            style="width: 60px;float:right"
                            round
                            size="mini"
                            type="info"
                            color="#F3C301"
                            @click="toAcceptOrder(item.id)"
                            >去接单</van-button
                          >
                        </template>
                      </van-cell>
                    </van-cell-group>
                  </div>
                </li>
              </ul>
            </van-list>
          </van-tab>
        </div>
      </van-tabs>
    </div>
    <!--
    <div class="action-bar" v-if="userStatus==1||userStatus==4">
      <div class="button">
        <van-button
          style="box-shadow: 0px 0px 7px 2px rgba(245, 194, 4, 0.49);"
          round
          block
          type="info"
          color="#F5C204"
          @click="goTodo"
          >入驻代办</van-button
        >
      </div>
    </div>
    -->
  </div>
</template>

<script>
import ServiceCateService from "@/api/service";
import { Toast } from "vant";
import Utils from "@/utils/utils";

export default {
  data() {
    return {
      loading: false,
      finished: false,
      orderList: [],
      order_type: 0,
      page: 1,
      serviceList: null,
    };
  },
  async beforeMount() {
    var managerId = Utils.getParmManagerIdFromUrl(window.location.href);
    this.managerId = managerId;
    this.$cookie.set("managerId", managerId);
    this.loadData(0);
    var token = this.$cookie.get("token");
    //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
    var params = {
      token: token,
    };
    const resp = await ServiceCateService.getServiceCate(this, params);
    if (resp.data.code == 10000) {
      this.serviceList = resp.data.data;
    }


  },

  methods: {
    loadData: function(type) {
      this.order_type = type;
      this.getStaffOrderList();
      this.page++;
    },
    async getStaffOrderList() {
      var params2 = {
        token: this.$cookie.get("token"),
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
        order_type: this.order_type,
        page: this.page,
      };
      const resp2 = await ServiceCateService.getStaffOrderList(this, params2);
      if (resp2.data.code == 10000) {
        var list = resp2.data.data;
        this.loading = false;
        if (list == null || list.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }
        // 将新数据与老数据进行合并
        this.orderList = this.orderList.concat(list);
      }
    },
    changeServiceType(name) {
      console.log(name);
      this.order_type = name;
      this.orderList = [];
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.loadData(name);
    },
    goPerson: function() {
      this.$router.push("/person");
    },
    goTodo: function() {
      this.$router.push("/person/auth");
    },
    toOrderList: function() {
      this.$router.push("/index");
    },
    toAcceptOrder: function(orderId) {
      console.log(orderId);
      console.log(this.userStatus);
      if (this.userStatus == 1) {
        console.log("aa");
        this.$router.push("/person/authDetail");
        return;
      }
      if (this.userStatus == 2) {
        Toast("代办员认证中,不能接单~");
        return;
      }
      this.$router.push({
        name: "orderAccept",
        params: {
          orderId: orderId,
        },
      });
    },
    goBack: function() {
      this.$router.go(-1);
    },
    goHome: function() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.van-tab {
  font-size: 13px !important;
}
.van-tab .van-tab__text {
  font-size: 13px !important;
}
.van-tab--active .van-tab__text {
  font-size: 14px !important;
}
.action-bar {
  position: fixed;
  bottom: 20px;
  width: 100%;
}
.action-bar .button {
  margin: auto 20px;
}
.header-container {
  background-color: #f5c204;
  padding: 16px 14px;
  background-size: 100% 100%;
  background: url("~@/assets/img/header-bg.png") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}
.header-container .avator-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: center;
}
.header-container .search-box {
  height: 36px;
  width: 100%;
  background: #fff;
  border-radius: 36px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 16px;
}
.search-box .pref-icon {
  margin: 0 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.search-box .input-box {
  width: calc(100% - 20px);
  margin: 0 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.input-box input {
  border: 0;
  width: 100%;
}
.search-box .search-icon {
  margin: 0 10px 0 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;
}

.avator-box .avator-img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}
.avator-img img {
  border-radius: 50px;
  width: 100%;
  height: 100%;
}
.avator-box .p-info {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.p-info .p-name {
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
}
.p-info .p-location {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.p-location p {
  font-size: 12px;
  color: #fff;
}
.p-location .icon {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.header-container .statistics-box {
  margin-top: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.statistics-box .item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}
.item .text {
  font-size: 26px;
  font-weight: 600;
  color: #ffffff;
}
.item .title {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
}

.order-box {
  margin: 10px 6px 0 6px;
  box-shadow: 0px 0px 7px 2px rgba(119, 119, 119, 0.05);
  border-radius: 4px;
}
.order-list-box {
  margin: 5px 6px;
}

.order-list-box li {
  margin-top: 6px;
  background-color: #ffffff;
}
.order-list-box li:first-child {
  margin-top: 0px;
  background-color: #ffffff;
}
.order-item-box {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.order-item-box .order-item-img {
  margin-left: 18px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
}
.order-item-img .zh-img {
  width: 60px !important;
  height: 60px !important;
}
.order-item-img img {
  width: 80px;
  height: 80px;
}
.order-item-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.order-item-box .order-item-content {
  width: calc(100% - 122px);
  padding: 10px 0 10px 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}

.order-item-content .header-box {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.order-item-content .item {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 8px;
}
.order-item-content .item .title p {
  color: #b3b3b3;
  font-size: 12px;
  width: 54px;
  text-align-last: justify;
}
.order-item-footer {
  width: 100%;
}

.order-item-content .item .text p {
  color: #333;
  font-size: 12px;
  font-weight: 400;
}
.header-box .title {
  font-size: 14px;
  color: #333;
}
.header-box .date {
  color: #b3b3b3;
}
.priceTitle {
  font-size: 12px;
  color: #333;
}
.price {
  font-size: 16px;
  color: #f5c204;
  font-weight: 700;
}

.van-cell-group {
  border-radius: 6px !important;
}
.van-cell-raduis {
  border-radius: 6px !important;
}
.van-cell-raduis-top {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
.van-cell-raduis-bottom {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
</style>
