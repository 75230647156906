<template>
  <div class="body-bg">
    <van-nav-bar
      title="排行榜"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>

    <div class="my-header">
      <div style="height: 30px"></div>
      <div class="conbox">
        <div class="avator-box">
          <div class="avator-info">
             
            <div class="avator">
              <img :src="avator" />
            </div>
             
            <div class="rank">
              <img v-if="rank==1" src="@/assets/img/wg1.png" />
              <img v-if="rank==2" src="@/assets/img/wg2.png" />
              <img v-if="rank==3" src="@/assets/img/wg3.png" />
            
            </div>
          </div>
          <div class="info">
            <p class="pName">{{ userName }}</p>
            <div class="divided"></div>
            <p class="pPhone">
            ¥{{fmoney(staff_allmoney,2)}}
            </p>
          </div>
        </div>
        <!--
        <div class="info-tips">
          <div class="progress">
            <van-progress pivot-text="" color="#f2826a" :percentage="hp" />
          </div>

          距下一等级差<span class="star">{{need_ordernum}}单</span>,金额差<span class="star"
            >{{need_pricenum}}元</span>,好评数差<span class="star">{{need_evaluatenum}}个</span>
        </div>
        -->
      </div>

    </div>

    <div class="content-box">
      <van-cell-group :border="false">
        <van-cell center  v-for="(item, index) in rankList"
              :key="index">
          <div slot="icon" class="cell-title spaceR">
            <img v-if="index==0" src="@/assets/img/rank11.png" />
            <img v-if="index==1" src="@/assets/img/rank2.png" />
            <img v-if="index==2" src="@/assets/img/rank3.png" />
            <p v-if="index >=3 ">{{index + 1}}</p>
          </div>
          <div slot="title" class="cell-content rank-color">
              <img :src="item.staff_img" class="spaceR" />
            <div class="van-cell-text spaceL">{{item.staff_name}}</div>
          </div>
          <div slot="default" class="rank-color">
            ¥{{fmoney(item.staff_allmoney,2)}}
          </div>
          
        </van-cell>    
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import UserService from "@/api/user";
import Service from "@/api/service";
//import { Toast } from "vant";
export default {
  data() {
    return {
      userName: "",
      userGrade: "",
      rank: "",
      phone: "",
      userOrderNum: "",
      userStatus: "",
      staff_allmoney: "",
      userBalance: "",
      avator: "",
      fans: "",
      hp: 0,
      need_ordernum: 0,
      need_evaluatenum: 0,
      need_pricenum: 0,
      rankList: []
    };
  },
  async beforeCreate() {
    //得到用户统计信息
    var token = this.$cookie.get("token");
    //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
    var params = {
      token: token,
    };
    const resp1 = await UserService.staffInfo(this, params);
    if (resp1.data.code == 10000) {
      this.userName = resp1.data.data.staff_name;
      this.userGrade = resp1.data.data.staff_grade;
      this.phone = resp1.data.data.staff_phone;
      this.userOrderNum = resp1.data.data.staff_ordernum;
      this.userStatus = resp1.data.data.status;
      this.staff_allmoney = resp1.data.data.staff_allmoney;
      //可提现金额
      this.userBalance = resp1.data.data.staff_balance;
      this.avator = resp1.data.data.staff_img;
      this.$cookie.set("staffImg", resp1.data.data.staff_img);
      this.fans = resp1.data.data.staff_fans;
      this.hp = resp1.data.data.hp * 100;
      this.need_ordernum = resp1.data.data.need_ordernum;
      this.need_evaluatenum = resp1.data.data.need_evaluatenum;
      this.need_pricenum = resp1.data.data.need_pricernum;
    }

     var params1 = {
      token: token,
      type: 1,
    };
    const resp2 = await Service.staffRank(this, params1);
    if (resp1.data.code == 10000) {
      this.rankList = resp2.data.data.list
      this.rank = resp2.data.data.myRangKing.num
    }
  },
  methods: {

    goBack: function() {
      this.$router.go(-1);
    },
    goHome: function() {
      this.$router.push("/");
    },
  

    fmoney(s, n) {
      n = n > 0 && n <= 20 ? n : 2;
      s = parseFloat((s + "").replace(/[^\d\\.-]/g, "")).toFixed(n) + "";
      var l = s
          .split(".")[0]
          .split("")
          .reverse(),
        r = s.split(".")[1];
      var t = "";
      for (var i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
      }
      return (
        t
          .split("")
          .reverse()
          .join("") +
        "." +
        r
      );
    },
  },
};
</script>

<style scoped>
.rank-color {
    color: #f5c204;
}   
.cell-content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
}
.cell-content img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
}
.divided {
    width: 2px;
    height: 14px;
    background: #fff;
    margin-left: 12px;
    margin-right: 12px;
}
.rule {
  position: absolute;
  font-size: 13px;
  color: #fff;
  top: 70px;
  right: 20px;
}
.container {
  overflow: hidden;
}
.star {
  color: #fb6354;
  font-weight: 800;
  display: inline-block;
}
.my-header {
  width: 100%;
  height: 240px;
  background: url("~@/assets/img/person-header-bg.png") no-repeat;
  background-size: 100% 100%;
}

.my-header .conbox {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.conbox .rank {
  margin-left: auto;
  margin-right: auto;
  margin-top: -96px;
  width: 48px;
  height: 48px;
}
.conbox .info-tips {
  margin-top: 10px;
  font-size: 9px;
}
.info-tips .progress {
  margin: 10px auto;
}
.rank img {
  width: 100%;
  height: 100%;
}
.conbox .avator-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

.conbox .avator {
    margin-top: 20px;
  width: 100px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.conbox .avator img {
  width: 60px;
  height: 60px;
  border-radius: 60px;
}

img {
  width: 16px;
  height: 16px;
}
.conbox .info {
    margin-top: 60px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.conbox .info .pName {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold
}

.conbox .info .pPhone {
  padding-right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.hp-box {
  margin: -80px 12px 0px 12px;
  padding: 6px 0;
  height: 60px;
  background: #fff;
  box-shadow: 0px 0px 7px 1px rgba(223, 223, 223, 0.45);
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.wallet-box {
  margin: 14px 12px 0px 12px;
  padding: 6px 0;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 0px 7px 1px rgba(223, 223, 223, 0.45);
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.wallet-box .line {
  width: 1px;
  height: 30px;
  background: #e6e6e6;
}
.wallet-box .wallet-item {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.wallet-item .title {
  font-size: 10px;
  font-family: PingFang SC;
  color: #b3b3b3;
}
.wallet-item .money {
  font-size: 18px;
  font-weight: 800;
  color: #fb6354;
  margin: 4px 0;
}
.wallet-item .button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 25px;
  background: #f5c204;
  border-radius: 12px;
}
.wallet-item .button:active {
  background: #f3cc40;
}
.wallet-item .button .txt {
  font-size: 13px;
  color: #333;
  margin-left: 4px;
}
.content-box {
  position: relative;
  margin: -80px 12px 20px 12px;
  background-color: #ffffff;
  padding: 4px 4px;
  border-radius: 4px;
  box-shadow: 0px 0px 7px 1px rgba(223, 223, 223, 0.45);
}

.button-box {
  margin: 30px 20px;
}

.cell-title {
    font-size: 12px;
    color: #808080;
  width: 20px;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
}
.cell-title img {
    width: 24px !important;
    height: 24px !important;
}
</style>
