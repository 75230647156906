export default class CommonUtils {
    static getParmValueFromUrl(url, paramName) {
        let params = url.split('&')
        for (let i = 0; i < params.length; i++) {
            let paramArray = params[i].split('=')
            if (paramArray[0] == paramName) {
                return paramArray[1]
            }
        }
    }

    static getParmCodeFromUrl(url) {
        if(url.split("?").length==1){
            return null;
        }
        console.log(url.split("?").length)
        return url.split("?")[1]
            .split("=")[1]
            .split("&")[0];
    }

    static getParmManagerIdFromUrl(url) {
        if(url.split("?").length==1){
            return null;
        }
        console.log(url.split("?").length)
        return url.split("?")[1]
            .split("=")[1];

    }
    static getParam(url) {
        if(url.split("?").length==1){
            return null;
        }
        console.log(url.split("?").length)
        return url.split("?")[1];

    }
    static getParmSharePIdFromUrl(url) {
        if(url.split("?").length==1){
            return null;
        }
        console.log(url.split("?").length)
        return url.split("?")[1]
            .split("=")[1];

    }
    
}