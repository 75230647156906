import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCookie from 'vue-cookie'
import { Icon } from 'vant';
import { Image as VanImage } from 'vant'
import { NavBar } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { Button } from 'vant';
import { PullRefresh } from 'vant';
import { Search } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Lazyload } from 'vant';
import { Grid, GridItem } from 'vant';
import { Divider } from 'vant';
import { NoticeBar } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { Stepper } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Switch } from 'vant';
import { Uploader } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Popup } from 'vant';
import { Picker } from 'vant';
import { AddressList } from 'vant';
import { AddressEdit } from 'vant';
import { Tab, Tabs } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { GoodsAction, GoodsActionIcon, GoodsActionButton } from 'vant';
import { Area } from 'vant';
import { TreeSelect } from 'vant';
import { Tag } from 'vant';
import { Rate } from 'vant';
import { Step, Steps } from 'vant';
import { DatetimePicker } from 'vant';
import moment from "moment";
import { Collapse, CollapseItem } from 'vant';
import { Toast } from 'vant';
import { List } from 'vant';
import { ImagePreview } from 'vant';
import { Progress } from 'vant';
import preventRepeatClick from '@/utils/preventRepeatClick';
import { CountDown } from 'vant';

Vue.use(CountDown);

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(preventRepeatClick);

import VueWechatTitle from 'vue-wechat-title';




import './assets/font/iconfont.css';
import './style.css';

Vue.use(Icon);
Vue.use(VanImage);
Vue.use(NavBar);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(PullRefresh);
Vue.use(Search);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Divider);
Vue.use(NoticeBar);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Stepper);
Vue.use(Field);
Vue.use(Form);
Vue.use(Switch);
Vue.use(Uploader);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(AddressList);
Vue.use(AddressEdit);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Area);
Vue.use(TreeSelect);
Vue.use(Tag);
Vue.use(Rate);
Vue.use(Step);
Vue.use(Steps);
Vue.use(DatetimePicker);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Toast);
Vue.use(List);
Vue.use(ImagePreview);
Vue.use(VueWechatTitle);
Vue.use(Progress);

Vue.prototype.$moment = moment;
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$cookie = VueCookie


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');