import Vue from "vue";
import VueCookie from "vue-cookie";
import VueRouter from "vue-router";
import Index from "../views/index/index";
import OrderHall from "../views/index/order-hall";
import Login from "../views/login/login";
import OrderList from "../views/order/orderList";
import Person from "../views/person/person";
import Punishment from "../views/punishment/index";
import Rank from "../views/rank/index";
import Reward from "../views/reward/index";
import Common from "../api/common";

Vue.use(VueRouter);
Vue.use(VueCookie);
Vue.prototype.$cookie = VueCookie;
//解决重复点击路由报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [

  {
    path: "/",
    name: "Index",
    component: Index,
    meta: {
      title: '极速代办',
      showNavTabs: true,
      noNeedAuth: true
    }
  },
  {
    path: "/order-hall",
    name: "OrderHall",
    component: OrderHall,
    meta: {
      title: '接单大厅',
      showNavTabs: true,
      noNeedAuth: true
    }
  },
  {
    path: "/punishment",
    name: "Punishment",
    component: Punishment,
    meta: {
      title: '违规处罚',
      showNavTabs: true,
      noNeedAuth: false
    }
  },
  {
    path: "/rank",
    name: "Rank",
    component: Rank,
    meta: {
      title: '排行榜',
      showNavTabs: true,
      noNeedAuth: false
    }
  },
  {
    path: "/reward",
    name: "Reward",
    component: Reward,
    meta: {
      title: '奖励',
      showNavTabs: true,
      noNeedAuth: false
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: '登录',
      noNeedAuth: true
    }
  },
  {
    path: "/login/phone",
    name: "PhoneLogin",
    component: () => import('@/views/login/phoneLogin'),
    meta: {
      title: '手机快捷登录',
      noNeedAuth: true
    }
  },
  {
    path: "/index",
    name: "Index",
    component: Index,
    meta: {
      title: '极速代办',
      showNavTabs: true,
      noNeedAuth: true
    }
  },
  {
    path: "/orderList",
    name: "OrderList",
    component: OrderList,
    meta: {
      title: '订单中心',
      showNavTabs: true,
      noNeedAuth: false
    }
  },
  {
    path: "/orderList/detail",
    name: "OrderDetail",
    component: () => import('@/views/order/orderDetail'),
    meta: {
      title: '订单详情',
      noNeedAuth: false
    }
  },
  {
    path: "/order/accept",
    name: "orderAccept",
    component: () => import('@/views/order/acceptOrder'),
    meta: {
      title: '订单详情',
      noNeedAuth: false
    }
  },
  {
    path: "/person",
    name: "Person",
    component: Person,
    meta: {
      title: '',
      showNavTabs: true,
      noNeedAuth: false
    }

  }, 
  {
    path: "/person/update",
    name: "UpdatePerson",
    component: () => import('@/views/person/updatePerson'),
    meta: {
      title: '个人信息'
    }

  },
  {
    path: "/person/card",
    name: "PersonCard",
    component: () => import('@/views/person/card/index'),
    meta: {
      title: '个人名片'
    }

  },{
    path: "/person/wallet",
    name: "Wallet",
    component: () => import('@/views/person/wallet/wallet'),
    meta: {
      title: '钱包'
    }
  },
  {
    path: "/person/wallet/crash",
    name: "WalletCrash",
    component: () => import('@/views/person/wallet/crashOut'),
    meta: {
      title: '提现'
    }
  },
  {
    path: "/person/wallet/crashResult",
    name: "CrashResult",
    component: () => import('@/views/person/wallet/crashResult'),
    meta: {
      title: '提现结果'
    }
  },
  {
    path: "/person/rule",
    name: "Rule",
    component: () => import('@/views/person/rule/index'),
    meta: {
      title: '规则说明'
    }
  },
  {
    path: "/person/invite",
    name: "Invite",
    component: () => import('@/views/person/invite/index'),
    meta: {
      title: '我的邀请'
    }
  },
  {
    path: "/person/invite/team",
    name: "Team",
    component: () => import('@/views/person/invite/team'),
    meta: {
      title: '我的队伍'
    }
  },
  {
    path: "/person/invite/teamOrder",
    name: "TeamOrder",
    component: () => import('@/views/person/invite/teamOrder'),
    meta: {
      title: '队伍订单'
    }
  },
  {
    path: "/person/changePhone",
    name: "ChangePhone",
    component: () => import('@/views/person/changePhone'),
    meta: {
      title: '更换号码'
    }
  },
  {
    path: "/person/contract",
    name: "Contract",
    component: () => import('@/views/person/contract'),
    meta: {
      title: '联系我们'
    }
  },
  {
    path: "/person/auth",
    name: "authIndex",
    component: () => import('@/views/person/authentication/index'),
    meta: {
      title: '资质认证',
      noNeedAuth: false
    }
  },
  {
    path: "/person/authDetail",
    name: "authDetail",
    component: () => import('@/views/person/authentication/auth'),
    meta: {
      title: '代办员认证',
      noNeedAuth: false
    }
  },
  {
    path: "/person/carAuth",
    name: "CarAuth",
    component: () => import('@/views/person/authentication/car-auth'),
    meta: {
      title: '车辆服务认证',
      noNeedAuth: false
    }
  },
  {
    path: "/person/enterpriseAuth",
    name: "EnterpriseAuth",
    component: () => import('@/views/person/authentication/enterprise-auth'),
    meta: {
      title: '企业服务认证',
      noNeedAuth: false
    }
  },
  {
    path: "/person/certificateAuth",
    name: "CertificateAuth",
    component: () => import('@/views/person/authentication/certificate-auth'),
    meta: {
      title: '办证服务认证',
      noNeedAuth: false
    }
  },
  {
    path: "/person/kownledgeAuth",
    name: "KownledgeAuth",
    component: () => import('@/views/person/authentication/kownledge-auth'),
    meta: {
      title: '知识产权服务认证',
      noNeedAuth: false
    }
  },

  {
    path: "/person/houseAuth",
    name: "KownledgeAuth",
    component: () => import('@/views/person/authentication/house-auth'),
    meta: {
      title: '装修服务认证',
      noNeedAuth: false
    }
  },
  {
    path: "/person/accountAuth",
    name: "AccountAuth",
    component: () => import('@/views/person/authentication/account-auth'),
    meta: {
      title: '财务服务认证',
      noNeedAuth: false
    }
  },
  
  {
    path: "/person/business",
    name: "Business",
    component: () => import('@/views/person/business/index'),
    meta: {
      title: '业务选择'
    }
  }
];


const router = new VueRouter({
  /*
  mode: "history",
  base: process.env.BASE_URL,
  */
  mode: "history",
  base: __dirname,
  routes,
});

export default router;

router.onError((error) => {
  const pattern = /Loading chunk chunk-(.*)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    location.reload();
  }
});



router.beforeEach(async (to, from, next) => {
  // 为页面设置title
  document.title = to.meta.title;
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
  if (VueCookie.get("isLogin") == null || VueCookie.get("isLogin") == "false") {
    VueCookie.set("token","");
    // if not, redirect to login page.
    console.log(to.meta.noNeedAuth)
    if (to.meta.noNeedAuth) {
      //不需要登录
      next()
    } else {
      var url = to.path;
      console.log(url)
      if (url.startsWith("/login")) { // 如果是登录页面的话，直接next() -->解决注销后的循环执行bug
        next()
      } else {
        window.location.href = Common.resources.auth.wxAuthorize;
        
        //next({
          //path: '/login',
          //path: Common.resources.auth.wxAuthorize
          //query: { returnUrl: to.fullPath }//把要跳转的地址作为参数传到下一步
        //})
        
      }
    }
  } else {
    next();
  }
  

})


