import axios from 'axios';
import common from './common';
import qs from 'qs'
/*
const http = axios.create({
    timeout: 15000
  });
  
  // request拦截器
  http.interceptors.request.use(function(config){
      config.data = config.data?config.data: {}
      // 把token添加到请求头,每次请求都带上
      config.headers = {
        //'Content-type': 'application/json',
        //'authorization': store.state.token==null?"":store.state.token
      }
      return config;
  })

  */
  export default class UserService {
      static staffRegister = async (vm,code,managerId,sharePId) => {
        const response = await axios.get(common.resources.user.register+'?code='+code + '&manager_id='+managerId+"&share_pid="+sharePId);
       
        //const response = await axios.get(common.resources.user.register+'?code='+code);
        return response;
      }
      static loginAsPhone = async (vm,params) => {
        const response = await axios.post(common.resources.user.phoneRegister,qs.stringify(params));
        return response;
      }
      static sendCode = async (vm,params) => {
        const response = await axios.post(common.resources.user.sendCode,qs.stringify(params));
        return response;
      }

      static staffInfo = async (vm,params) => {
        const response = await axios.post(common.resources.user.staffInfo,qs.stringify(params));
        return response;
      }

      static staffInfoIndex = async (vm,params) => {
        const response = await axios.post(common.resources.user.staffInfoIndex,qs.stringify(params));
        return response;
      }

      static upStaffHeadimg = async (vm,params) => {
        const response = await axios.post(common.resources.user.upStaffHeadimg,qs.stringify(params));
        return response;
      }

      
      static todoStaffCert = async (vm,params) => {
        const response = await axios.post(common.resources.user.todoStaffCert,qs.stringify(params));
        return response;
      }

      static changOrderRound = async (vm,params) => {
        const response = await axios.post(common.resources.user.changOrderRound,qs.stringify(params));
        return response;
      }
      
      static changOrderType = async (vm,params) => {
        const response = await axios.post(common.resources.user.changOrderType,qs.stringify(params));
        return response;
      }

      static changStatus = async (vm,params) => {
        const response = await axios.post(common.resources.user.changStatus,qs.stringify(params));
        return response;
      }

      static applyCashOut = async (vm,params) => {
        const response = await axios.post(common.resources.user.applyCashOut,qs.stringify(params));
        return response;
      }

      static getCashOutList = async (vm,params) => {
        const response = await axios.post(common.resources.user.getCashOutList,qs.stringify(params));
        return response;
      }

      static getStaffAuth = async (vm,params) => {
        const response = await axios.post(common.resources.user.getStaffAuth,qs.stringify(params))
        return response;
      }


      static getStaffAuthInfo = async (vm,params) => {
        const response = await axios.post(common.resources.user.getStaffAuthInfo,qs.stringify(params))
        return response;
      }


      static updateStaffAuthInfo = async (vm,params) => {
        const response = await axios.post(common.resources.user.updateStaffAuthInfo,qs.stringify(params))
        return response;
      }

      

  }
