import axios from 'axios';
import common from './common';
import qs from 'qs'
/*
const http = axios.create({
    timeout: 15000
  });
  
  // request拦截器
  http.interceptors.request.use(function(config){
      config.data = config.data?config.data: {}
      // 把token添加到请求头,每次请求都带上
      config.headers = {
        //'Content-type': 'application/json',
        //'authorization': store.state.token==null?"":store.state.token
      }
      return config;
  })

  */
export default class ServiceCateService {
  static getServiceCate = async (vm, obj) => {
    const response = await axios.post(common.resources.service.serviceCate, qs.stringify(obj));
    return response;
  }
  static getCarServiceList = async (vm, obj) => {
    const response = await axios.post(common.resources.service.serviceList, qs.stringify(obj));
    return response;
  }
  //可接单列表
  static getStaffOrderList = async (vm, obj) => {
    const response = await axios.post(common.resources.service.getStaffOrderList, qs.stringify(obj));
    return response;
  }
  //接单API
  static staffGetOrder = async (vm, obj) => {
    const response = await axios.post(common.resources.service.staffGetOrder, qs.stringify(obj));
    return response;
  }

  //开始服务
  static staffServiceOrder = async (vm, obj) => {
    const response = await axios.post(common.resources.service.staffServiceOrder, qs.stringify(obj));
    return response;
  }

  //更新进展
  static staffUpSpeed = async (vm, obj) => {
    const response = await axios.post(common.resources.service.staffUpSpeed, qs.stringify(obj));
    return response;
  }

  static staffOrderDetail = async (vm, obj) => {
    const response = await axios.post(common.resources.service.staffOrderDetail, qs.stringify(obj));
    return response;
  }

  static staffOrderEnd = async (vm, obj) => {
    const response = await axios.post(common.resources.service.staffOrderEnd, qs.stringify(obj));
    return response;
  }

  static myOrderList = async (vm, obj) => {
    const response = await axios.post(common.resources.service.myOrderList, qs.stringify(obj));
    return response;
  }

  //退款列表
  static staffGetUserCancelOrder = async (vm, obj) => {
    const response = await axios.post(common.resources.service.staffGetUserCancelOrder, qs.stringify(obj));
    return response;
  }

  static getContentFormPid = async (vm, obj) => {
    const response = await axios.post(common.resources.service.getContentFormPid, qs.stringify(obj));
    return response;
  }
  
  static staffShare =async (vm,obj) => {
    let config = {
      headers:{'Content-Type':'application/json'}
    }; 
    const response = await axios.post(common.resources.user.staffShare,qs.stringify(obj),config);
    return response;
  }

  static staffShare =async (vm,obj) => {
    let config = {
      headers:{'Content-Type':'application/json'}
    }; 
    const response = await axios.post(common.resources.user.staffShare,qs.stringify(obj),config);
    return response;
  }

  static staffRank =async (vm,obj) => {
    const response = await axios.post(common.resources.service.rank,qs.stringify(obj));
    return response;
  }

  static toDoRewardList = async (vm,obj) => {
    const response = await axios.post(common.resources.service.toDoRewardList,qs.stringify(obj));
    return response;
  }

  static getReward = async (vm,obj) => {
    const response = await axios.post(common.resources.service.getReward,qs.stringify(obj));
    return response;
  }

  static getStaffFins = async (vm,obj) => {
    const response = await axios.post(common.resources.service.staffFins,qs.stringify(obj));
    return response;
  }


  static getStaffFinsOrder = async (vm,obj) => {
    const response = await axios.post(common.resources.service.finsOrder,qs.stringify(obj));
    return response;
  }

  

}