<template>
  <div class="container">
    <van-nav-bar
      title="订单中心"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <img src="@/assets/img/index-o.png" style="width: 16px;height:16px" />
      </template>
    </van-nav-bar>
    <van-tabs
      sticky
      active="0"
      color="#F3C301"
      class="tabs"
      title-active-color="#F3C301"
      line-width="40rpx"
      line-height="4rpx"
      @click="changeOrderType"
    >
      <van-tab title="全部" name="0">
        <div class="tab-container">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="loadData(0)"
          >
            <div
              @click="toDetail(item)"
              v-for="(item, index) in orderList"
              :key="index"
            >
              <div class="kbox-gray5"></div>
              <div class="order-box">
                <div class="order-box_1" v-if="item.refund_state == 0">
                  <div class="info-box">
                    <div v-if="item.status == 2" class="title yjd"></div>
                    <div v-else-if="item.status == 3" class="title fwz"></div>
                    <div v-else-if="item.status == 4" class="title ybj"></div>
                    <div v-else-if="item.status == 5" class="title dpj"></div>
                    <div v-else-if="item.status == 6" class="title ywc"></div>

                    <div class="order-info van-hairline--bottom">
                      <div class="info">
                        <div class="p1">
                          <p class="txt space">订单编号</p>
                          <p class="txt">{{ item.order_no }}</p>
                        </div>
                        <div class="p2 light">
                          <p class="txt space">{{ item.zh_name }}</p>
                          <p class="txt">{{ item.create_at }}</p>
                        </div>
                      </div>
                      <div class="tag-box">
                        <div class="tag" v-if="item.status == 2">
                          <img src="@/assets/img/yjd.png" />
                          <div class="txt">已接单</div>
                        </div>
                        <div class="tag" v-if="item.status == 3">
                          <img src="@/assets/img/fwz.png" />
                          <div class="txt">服务中</div>
                        </div>
                        <div class="tag" v-if="item.status == 4">
                          <img src="@/assets/img/ywc.png" />
                          <div class="txt">已办结</div>
                        </div>
                        <div class="tag" v-if="item.status == 5">
                          <img src="@/assets/img/ywc.png" />
                          <div class="txt">已完成</div>
                        </div>
                        <div class="tag" v-if="item.status == 6">
                          <img src="@/assets/img/dpj.png" />
                          <div class="txt">已评价</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="order-content van-hairline--bottom">
                    <div class="p2 gray">
                      <p class="txt-title space">服务需求:</p>
                      <p class="txt">
                        <!--
                        <span
                          v-for="(item, index) in item.orderDataArr"
                          :key="index"
                        >
                          {{ item }}
                        </span>
                        -->
                        {{item.remarks}}
                      </p>
                    </div>
                    <div class="p2 gray" >
                      <p class="txt-title space">用工时长:</p>
                      <p class="txt">{{ item.long_time }}</p>
                    </div>
                    <div class="p2 gray">
                      <p class="txt-title space">数量:</p>
                      <p class="txt">{{ item.order_num }}</p>
                    </div>
                    <div class="p2 gray">
                      <p class="txt-title space">位置:</p>
                      <p class="txt">{{ item.order_area }}</p>
                    </div>
                    <div class="contract-box">
                      <div>
                        <div class="p2 gray">
                          <p class="txt-title space">联系人:</p>
                          <p class="txt">{{ item.express_name }}</p>
                        </div>
                        <div class="p2 gray">
                          <p class="txt-title space">联系电话:</p>
                          <p class="txt">{{ item.express_phone }}</p>
                        </div>
                      </div>
                      <van-icon
                        name="phone-o"
                        size="24px"
                        color="#F3C301"
                        @click.stop="phoneCall(item.express_phone)"
                      />
                    </div>
                  </div>
                  <div class="action-bar">
                    <div class="price-box">
                      <p class="label space">总费用:</p>
                      <p class="price">￥{{ item.real_price }}</p>
                    </div>
                    <div class="button-box">
                      <van-button
                        v-if="item.status == 2"
                        style="width:70px;margin-top:8px;padding-top: 2px"
                        round
                        type="info"
                        color="#f48057"
                        size="mini"
                        @click.stop="startService(item.id)"
                        >去服务
                      </van-button>
                      <van-button
                        v-if="item.status == 3"
                        style="width:70px;margin-top:8px;padding-top: 2px"
                        round
                        type="info"
                        color="#64a0e4"
                        size="mini"
                        @click.stop="finishOrder(item.id)"
                        >确认办结
                      </van-button>
                      <van-button
                        v-if="item.status == 3"
                        style="width:70px;margin-top:8px;padding-top: 2px"
                        round
                        plain
                        type="info"
                        color="#64a0e4"
                        size="mini"
                        @click.stop="updateOrder(item.id)"
                        >更新进度
                      </van-button>
                    </div>
                  </div>
                </div>
                <div class="order-box_1" v-else>
                  <div class="info-box">
                    <div class="title yjd"></div>
                    <div class="order-info van-hairline--bottom">
                      <div class="info">
                        <div class="p1">
                          <p class="txt space">订单编号</p>
                          <p class="txt">{{ item.order_no }}</p>
                        </div>
                        <div class="p2 light">
                          <p class="txt space">{{ item.zh_name }}</p>
                          <p class="txt">{{ item.create_at }}</p>
                        </div>
                      </div>
                      <div class="tag-box">
                        <div class="tag" v-if="item.refund_state == 1">
                          <img src="@/assets/img/dzf.png" />
                          <div class="txt">退款中</div>
                        </div>
                        <div class="tag" v-if="item.refund_state == 2">
                          <img src="@/assets/img/dzf.png" />
                          <div class="txt">已退款</div>
                        </div>
                        <div class="tag" v-if="item.refund_state == 3">
                          <img src="@/assets/img/dzf.png" />
                          <div class="txt">拒绝退款</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="order-content van-hairline--bottom">
                    <div class="p2 gray">
                      <p class="txt-title space">服务需求:</p>
                      <p class="txt">
                        <!--
                        <span
                          v-for="(item, index) in item.orderDataArr"
                          :key="index"
                        >
                          {{ item }}
                        </span>
                        -->
                        {{item.remarks}}
                      </p>
                    </div>
                    <div class="p2 gray">
                      <p class="txt-title space">用工时长:</p>
                      <p class="txt">{{ item.long_time }}</p>
                    </div>
                    <div class="p2 gray">
                      <p class="txt-title space">数量:</p>
                      <p class="txt">{{ item.order_num }}</p>
                    </div>
                    <div class="p2 gray">
                      <p class="txt-title space">位置:</p>
                      <p class="txt">{{ item.order_area }}</p>
                    </div>
                    <div
                      class="contract-box"
                      wx:if="item.status ==2||item.status==3"
                    >
                      <div>
                        <div class="p2 gray">
                          <p class="txt-title space">联系人:</p>
                          <p class="txt">{{ item.express_name }}</p>
                        </div>
                        <div class="p2 gray">
                          <p class="txt-title space">联系电话:</p>
                          <p class="txt">{{ item.express_phone }}</p>
                        </div>
                      </div>
                      <van-icon
                        name="phone-o"
                        size="24px"
                        color="#F3C301"
                        @click.stop="phoneCall(item.express_phone)"
                      />
                    </div>
                  </div>
                  <div class="action-bar">
                    <div class="price-box">
                      <p class="label space">总费用:</p>
                      <p class="price">￥{{ item.real_price }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="未完成" name="1">
        <div class="tab-container">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="loadData(1)"
          >
            <div
              @click="toDetail(item)"
              v-for="(item, index) in orderList"
              :key="index"
            >
              <div class="kbox-gray5"></div>
              <div class="order-box">
                <div class="order-box_1">
                  <div class="info-box">
                    <div v-if="item.status == 2" class="title yjd"></div>
                    <div v-else-if="item.status == 3" class="title fwz"></div>
                    <div class="order-info van-hairline--bottom">
                      <div class="info">
                        <div class="p1">
                          <p class="txt space">订单编号</p>
                          <p class="txt">{{ item.order_no }}</p>
                        </div>
                        <div class="p2 light">
                          <p class="txt space">{{ item.zh_name }}:</p>
                          <p class="txt">{{ item.create_at }}</p>
                        </div>
                      </div>
                      <div class="tag-box">
                        <div class="tag" v-if="item.status == 2">
                          <img src="@/assets/img/yjd.png" />
                          <div class="txt">已接单</div>
                        </div>
                        <div class="tag" v-if="item.status == 3">
                          <img src="@/assets/img/fwz.png" />
                          <div class="txt">服务中</div>
                        </div>
                        <div class="tag" v-if="item.status == 4">
                          <img src="@/assets/img/ywc.png" />
                          <div class="txt">已办结</div>
                        </div>
                        <div class="tag" v-if="item.status == 5">
                          <img src="@/assets/img/ywc.png" />
                          <div class="txt">已完成</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="order-content van-hairline--bottom">
                    <div class="p2 gray">
                      <p class="txt-title space">服务需求:</p>
                      <p class="txt">
                        <!--
                        <span
                          v-for="(item, index) in item.orderDataArr"
                          :key="index"
                        >
                          {{ item }}
                        </span>
                        -->
                        {{item.remarks}}
                      </p>
                    </div>
                    <div class="p2 gray">
                      <p class="txt-title space">用工时长:</p>
                      <p class="txt">{{ item.long_time }}</p>
                    </div>
                    <div class="p2 gray">
                      <p class="txt-title space">数量:</p>
                      <p class="txt">{{ item.order_num }}</p>
                    </div>
                    <div class="p2 gray">
                      <p class="txt-title space">位置:</p>
                      <p class="txt">{{ item.order_area }}</p>
                    </div>
                    <div
                      class="contract-box"
                      wx:if="item.status ==2||item.status==3"
                    >
                      <div>
                        <div class="p2 gray">
                          <p class="txt-title space">联系人:</p>
                          <p class="txt">{{ item.express_name }}</p>
                        </div>
                        <div class="p2 gray">
                          <p class="txt-title space">联系电话:</p>
                          <p class="txt">{{ item.express_phone }}</p>
                        </div>
                      </div>
                      <van-icon
                        name="phone-o"
                        size="24px"
                        color="#F3C301"
                        @click.stop="phoneCall(item.express_phone)"
                      />
                    </div>
                  </div>
                  <div class="action-bar">
                    <div class="price-box">
                      <p class="label space">总费用:</p>
                      <p class="price">￥{{ item.real_price }}</p>
                    </div>
                    <div class="button-box">
                      <van-button
                        v-if="item.status == 2"
                        style="width:70px;margin-top:8px;padding-top: 2px"
                        round
                        type="info"
                        color="#f48057"
                        size="mini"
                        @click.stop="startService(item.id)"
                        >去服务
                      </van-button>
                      <van-button
                        v-if="item.status == 3"
                        style="width:70px;margin-top:8px;padding-top: 2px"
                        round
                        type="info"
                        color="#64a0e4"
                        size="mini"
                        @click.stop="finishOrder(item.id)"
                        >确认办结
                      </van-button>
                      <van-button
                        v-if="item.status == 3"
                        style="width:70px;margin-top:8px;padding-top: 2px"
                        round
                        plain
                        type="info"
                        color="#64a0e4"
                        size="mini"
                        @click.stop="updateOrder(item.id)"
                        >更新进度
                      </van-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="已完成" name="2">
        <div class="tab-container">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="loadData(2)"
          >
            <div
              @click="toDetail(item)"
              v-for="(item, index) in orderList"
              :key="index"
            >
              <div class="kbox-gray5"></div>
              <div class="order-box">
                <div class="order-box_1">
                  <div class="info-box">
                    <div v-if="item.status == 4" class="title ybj"></div>
                    <div v-else-if="item.status == 5" class="title dpj"></div>
                    <div v-else-if="item.status == 6" class="title ywc"></div>
                    <div class="order-info van-hairline--bottom">
                      <div class="info">
                        <div class="p1">
                          <p class="txt space">订单编号</p>
                          <p class="txt">{{ item.order_no }}</p>
                        </div>
                        <div class="p2 light">
                          <p class="txt space">{{ item.zh_name }}</p>
                          <p class="txt">{{ item.create_at }}</p>
                        </div>
                      </div>
                      <div class="tag-box">
                        <div class="tag" v-if="item.status == 4">
                          <img src="@/assets/img/ywc.png" />
                          <div class="txt">已办结</div>
                        </div>
                        <div class="tag" v-if="item.status == 5">
                          <img src="@/assets/img/ywc.png" />
                          <div class="txt">已完成</div>
                        </div>
                        <div class="tag" v-if="item.status == 6">
                          <img src="@/assets/img/dpj.png" />
                          <div class="txt">已评价</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="order-content van-hairline--bottom">
                    <div class="p2 gray">
                      <p class="txt-title space">服务需求:</p>
                      <p class="txt">
                        <!--
                        <span
                          v-for="(item, index) in item.orderDataArr"
                          :key="index"
                        >
                          {{ item }}
                        </span>
                        -->
                        {{item.remarks}}
                      </p>
                    </div>
                    <div class="p2 gray">
                      <p class="txt-title space">用工时长:</p>
                      <p class="txt">{{ item.long_time }}</p>
                    </div>
                    <div class="p2 gray">
                      <p class="txt-title space">数量:</p>
                      <p class="txt">{{ item.order_num }}</p>
                    </div>
                    <div class="p2 gray">
                      <p class="txt-title space">位置:</p>
                      <p class="txt">{{ item.order_area }}</p>
                    </div>
                    <div
                      class="contract-box"
                      wx:if="item.status ==2||item.status==3"
                    >
                      <div>
                        <div class="p2 gray">
                          <p class="txt-title space">联系人:</p>
                          <p class="txt">{{ item.express_name }}</p>
                        </div>
                        <div class="p2 gray">
                          <p class="txt-title space">联系电话:</p>
                          <p class="txt">{{ item.express_phone }}</p>
                        </div>
                      </div>
                      <van-icon
                        name="phone-o"
                        size="24px"
                        color="#F3C301"
                        @click.stop="phoneCall(item.express_phone)"
                      />
                    </div>
                  </div>
                  <div class="action-bar">
                    <div class="price-box">
                      <p class="label space">总费用:</p>
                      <p class="price">￥{{ item.real_price }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="退款" name="3">
        <div class="tab-container">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="loadData(3)"
          >
            <div
              @click="toDetail(item)"
              v-for="(item, index) in orderList"
              :key="index"
            >
              <div class="kbox-gray5"></div>
              <div class="order-box">
                <div class="order-box_1">
                  <div class="info-box">
                    <div class="title yjd"></div>
                    <div class="order-info van-hairline--bottom">
                      <div class="info">
                        <div class="p1">
                          <p class="txt space">订单编号</p>
                          <p class="txt">{{ item.order_no }}</p>
                        </div>
                        <div class="p2 light">
                          <p class="txt space">{{ item.zh_name }}</p>
                          <p class="txt">{{ item.create_at }}</p>
                        </div>
                      </div>
                      <div class="tag-box">
                        <div class="tag" v-if="item.refund_state == 1">
                          <img src="@/assets/img/dzf.png" />
                          <div class="txt">退款中</div>
                        </div>
                        <div class="tag" v-if="item.refund_state == 2">
                          <img src="@/assets/img/dzf.png" />
                          <div class="txt">已退款</div>
                        </div>
                        <div class="tag" v-if="item.refund_state == 3">
                          <img src="@/assets/img/dzf.png" />
                          <div class="txt">拒绝退款</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="order-content van-hairline--bottom">
                    <div class="p2 gray">
                      <p class="txt-title space">服务需求:</p>
                      <p class="txt">
                        <!--
                        <span
                          v-for="(item, index) in item.orderDataArr"
                          :key="index"
                        >
                          {{ item }}
                        </span>
                        -->
                        {{item.remarks}}
                      </p>
                    </div>
                    <div class="p2 gray" >
                      <p class="txt-title space">用工时长:</p>
                      <p class="txt">{{ item.long_time }}</p>
                    </div>
                    <div class="p2 gray">
                      <p class="txt-title space">数量:</p>
                      <p class="txt">{{ item.order_num }}</p>
                    </div>
                    <div class="p2 gray">
                      <p class="txt-title space">位置:</p>
                      <p class="txt">{{ item.order_area }}</p>
                    </div>
                    <div
                      class="contract-box"
                      wx:if="item.status ==2||item.status==3"
                    >
                      <div>
                        <div class="p2 gray">
                          <p class="txt-title space">联系人:</p>
                          <p class="txt">{{ item.express_name }}</p>
                        </div>
                        <div class="p2 gray">
                          <p class="txt-title space">联系电话:</p>
                          <p class="txt">{{ item.express_phone }}</p>
                        </div>
                      </div>
                      <van-icon
                        name="phone-o"
                        size="24px"
                        color="#F3C301"
                        @click.stop="phoneCall(item.express_phone)"
                      />
                    </div>
                  </div>
                  <div class="action-bar">
                    <div class="price-box">
                      <p class="label space">总费用:</p>
                      <p class="price">￥{{ fmoney(item.real_price, 2) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Toast } from "vant";
import ServiceCateService from "@/api/service";

export default {
  inject: ["reload"],
  data() {
    return {
      loading: false,
      finished: false,
      orderList: [],
      order_type: 0,
      page: 1,
    };
  },
  methods: {
    phoneCall: function(phone) {
      window.location.href = "tel://" + phone;
    },
    async startService(orderId) {
      var params = {
        orderId: orderId,
        token: this.$cookie.get("token"),
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
        //
        //token: 'f4f063406333af996540fdd9c108a4c6-2',
      };
      const resp = await ServiceCateService.staffServiceOrder(this, params);
      if (resp.data.code == 10000) {
        console.log(resp.data.data);
        //接单成功,更新状态
        Toast("您已开始服务,请注意更新服务进度!");
        this.orderList = [];
        this.page = 1;
        this.loading = false;
        this.finished = false;
        this.loadData(this.order_type);
      }
    },
    toDetail: function(orderInfo) {
      if (orderInfo.status == 2) {
        //已接单不能跳转到详情页
        return;
      }
      //已退款的不能跳转到详情页
      if (orderInfo.refund_state != null && orderInfo.refund_state != 0) {
        console.log("AAA");
        return;
      }
      var orderId= orderInfo.id
      console.log(orderInfo)
      this.$router.push({
        path: "/orderList/detail",
        query: {
          orderId: orderId,
        },
      });
    },
    async finishOrder(orderId) {
      var params2 = {
        token: this.$cookie.get("token"),
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
        //
        //token: 'f4f063406333af996540fdd9c108a4c6-2',
        orderid: orderId,
      };
      const resp2 = await ServiceCateService.staffOrderEnd(this, params2);
      if (resp2.data.code == 10000) {
        console.log(resp2.data);
        this.reload();
      }
    },
    async updateOrder(orderId) {
      this.$router.push({
        name: "OrderDetail",
        params: {
          orderId: orderId,
        },
      });
    },
    goBack: function() {
      this.$router.go(-1);
    },
    goHome: function() {
      this.$router.push("/");
    },
    loadData: function(type) {
      this.order_type = type;
      if (type == 3) {
        //获取退款订单
        this.getRefundOrderList();
      } else {
        this.getMyOrderList();
      }
      this.page++;
    },
    async getMyOrderList() {
      var params2 = {
        token: this.$cookie.get("token"),
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
        //
        //token: 'f4f063406333af996540fdd9c108a4c6-2',
        status: this.order_type,
        page: this.page,
      };
      const resp2 = await ServiceCateService.myOrderList(this, params2);
      if (resp2.data.code == 10000) {
        var list = resp2.data.data;
        this.loading = false;
        if (list == null || list.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }
        // 将新数据与老数据进行合并
        this.orderList = this.orderList.concat(list);
      } else {
        this.orderList = this.orderList.concat([]);
      }
    },
    //获取退款订单列表
    async getRefundOrderList() {
      var params2 = {
        token: this.$cookie.get("token"),
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
        //
        //token: 'f4f063406333af996540fdd9c108a4c6-2',
        page: this.page,
      };
      const resp2 = await ServiceCateService.staffGetUserCancelOrder(
        this,
        params2
      );
      if (resp2.data.code == 10000) {
        var list = resp2.data.data;
        this.loading = false;
        if (list == null || list.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }
        // 将新数据与老数据进行合并
        this.orderList = this.orderList.concat(list);
      }
    },
    changeOrderType(name) {
      console.log(name);
      this.order_type = name;
      this.orderList = [];
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.loadData(name);
    },
    fmoney(s, n) {
      n = n > 0 && n <= 20 ? n : 2;
      s = parseFloat((s + "").replace(/[^\d\\.-]/g, "")).toFixed(n) + "";
      var l = s
          .split(".")[0]
          .split("")
          .reverse(),
        r = s.split(".")[1];
      var t = "";
      for (var i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
      }
      return (
        t
          .split("")
          .reverse()
          .join("") +
        "." +
        r
      );
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #fafafa;
  padding-bottom: 10px;
}

.tabClass {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #b3b3b3 !important;
}

.tabActiveClass {
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #f3c301 !important;
  font-weight: 700 !important;
}

.tab-container {
  overflow: hidden;
}

.order-box {
  background: #fff;
  border: 1px solid #fff;
  margin-left: 6px;
  margin-right: 6px;
  padding-bottom: 6px;
  border-radius: 2px;
}

.order-box_1 {
  padding: 10px 0 8px 0;
}

.order-box_1 .order-content {
  margin-top: 8px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
}
.order-box_1 .order-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
}
.order-box_1 .info-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.info-box .title {
  height: 20px;
  width: 3px;
}
.info-box .title.yjd {
  background-color: #f48057;
}
.info-box .title.fwz {
  background-color: #64a0e4;
}
.info-box .title.ywc {
  background-color: #51c9d7;
}

.info-box .title.dpj {
  background-color: #51c9d7;
}

.order-box_1 .p1 {
  color: #333;
  font-size: 14px;
  padding-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.order-box_1 .p2 {
  color: #333;
  font-size: 12px;
  padding-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.txt-title {
  width: 54px;
}

.p1.light {
  color: #b3b3b3;
}

.p2.light {
  color: #b3b3b3;
}
.p2.gray {
  color: #808080;
}

.order-box_1 .p1 .txt {
  font-size: 14px;
}

.order-box_1 .p2 .txt {
  font-size: 12px;
  max-width: 260px;
}
.tabs {
  z-index: 100 !important;
}
.tag-box {
  margin-right: -18px;
  z-index: 1;
}
.tag {
  width: 75px;
  height: 30px;
  position: relative;
  text-align: center;
  line-height: 25px;
}

.tag img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.tag .txt {
  color: #ffffff;
  font-size: 12px;
  margin: auto 0;
}
.contract-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.action-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 6px;
}
.action-bar .button-box {
  width: 154px;
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-content: center;
}
.action-bar .price-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
}
.price-box .label {
  font-size: 12px;
  color: #333333;
}

.price-box .price {
  font-size: 14px;
  font-weight: 700;
  color: #f3c301;
}

.space {
  margin-right: 10px;
}
.van-hairline--bottom {
  color: #fafafa !important;
}
</style>
