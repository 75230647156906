<template>
  <div class="body-bg">
    <van-nav-bar
      title="违规处罚"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <img src="@/assets/img/index-o.png" style="width: 16px;height:16px" />
      </template>
    </van-nav-bar>
    <!--
    <div class="service-box">
        <div class="service-item">
            <div class="item-header van-hairline--bottom">
                <div class="info-header " >
                    <div class="title-box">
                        <div class="title">车辆服务</div>
                        <div class="date">2020/09/20</div>
                    </div>
                     <div class="title-box">
                        <div class="title gray">订单编号</div>
                        <div class="date">414154654646456464</div>
                    </div>
                </div>
                <div class="btn">
                    <van-button color="#f5c204" block round size="small">投诉</van-button>
                </div>
            </div>
            <div class="order-content">
                <div class="p-item">
                    <div class="title">服务需求: </div>
                    <div class="des">1辆非营运车辆上户；2辆非营运车辆临牌；1辆非营运车辆上户；2辆非营运车辆临牌；1辆非营运车辆上户；2辆非营运车辆临牌</div>
                </div>
                <div class="p-item">
                    <div class="title">订单状态: </div>
                    <div class="des">已完结</div>
                </div>
                 <div class="p-statis-info">
                    <div class="title">总费用: </div>
                    <div class="hx-price">￥350</div>
                     <div class="price spaceL">￥280</div>
                </div>
                 <div class="p-tips">
                      处理结果:   补贴用户70元
                 </div>
            </div>
        </div>
           <div class="service-item">
            <div class="item-header van-hairline--bottom">
                <div class="info-header " >
                    <div class="title-box">
                        <div class="title">车辆服务</div>
                        <div class="date">2020/09/20</div>
                    </div>
                     <div class="title-box">
                        <div class="title gray">订单编号</div>
                        <div class="date">414154654646456464</div>
                    </div>
                </div>
                <div class="btn">
                    <van-button color="#f5c204" block round size="small">投诉</van-button>
                </div>
            </div>
            <div class="order-content">
                <div class="p-item">
                    <div class="title">服务需求: </div>
                    <div class="des">1辆非营运车辆上户；2辆非营运车辆临牌；1辆非营运车辆上户；2辆非营运车辆临牌；1辆非营运车辆上户；2辆非营运车辆临牌</div>
                </div>
                <div class="p-item">
                    <div class="title">订单状态: </div>
                    <div class="des">已完结</div>
                </div>
                 <div class="p-statis-info">
                    <div class="title">总费用: </div>
                    <div class="hx-price">￥350</div>
                     <div class="price spaceL">￥280</div>
                </div>
                 <div class="p-tips">
                      处理结果:   补贴用户70元
                 </div>
            </div>
        </div>
           <div class="service-item">
            <div class="item-header van-hairline--bottom">
                <div class="info-header " >
                    <div class="title-box">
                        <div class="title">车辆服务</div>
                        <div class="date">2020/09/20</div>
                    </div>
                     <div class="title-box">
                        <div class="title gray">订单编号</div>
                        <div class="date">414154654646456464</div>
                    </div>
                </div>
                <div class="btn">
                    <van-button color="#f5c204" block round size="small">投诉</van-button>
                </div>
            </div>
            <div class="order-content">
                <div class="p-item">
                    <div class="title">服务需求: </div>
                    <div class="des">1辆非营运车辆上户；2辆非营运车辆临牌；1辆非营运车辆上户；2辆非营运车辆临牌；1辆非营运车辆上户；2辆非营运车辆临牌</div>
                </div>
                <div class="p-item">
                    <div class="title">订单状态: </div>
                    <div class="des">已完结</div>
                </div>
                 <div class="p-statis-info">
                    <div class="title">总费用: </div>
                    <div class="hx-price">￥350</div>
                     <div class="price spaceL">￥280</div>
                </div>
                 <div class="p-tips">
                      处理结果:   补贴用户70元
                 </div>
            </div>
        </div>
           <div class="service-item">
            <div class="item-header van-hairline--bottom">
                <div class="info-header " >
                    <div class="title-box">
                        <div class="title">车辆服务</div>
                        <div class="date">2020/09/20</div>
                    </div>
                     <div class="title-box">
                        <div class="title gray">订单编号</div>
                        <div class="date">414154654646456464</div>
                    </div>
                </div>
                <div class="btn">
                    <van-button color="#f5c204" block round size="small">投诉</van-button>
                </div>
            </div>
            <div class="order-content">
                <div class="p-item">
                    <div class="title">服务需求: </div>
                    <div class="des">1辆非营运车辆上户；2辆非营运车辆临牌；1辆非营运车辆上户；2辆非营运车辆临牌；1辆非营运车辆上户；2辆非营运车辆临牌</div>
                </div>
                <div class="p-item">
                    <div class="title">订单状态: </div>
                    <div class="des">已完结</div>
                </div>
                 <div class="p-statis-info">
                    <div class="title">总费用: </div>
                    <div class="hx-price">￥350</div>
                     <div class="price spaceL">￥280</div>
                </div>
                 <div class="p-tips">
                      处理结果:   补贴用户70元
                 </div>
            </div>
        </div>
           <div class="service-item">
            <div class="item-header van-hairline--bottom">
                <div class="info-header " >
                    <div class="title-box">
                        <div class="title">车辆服务</div>
                        <div class="date">2020/09/20</div>
                    </div>
                     <div class="title-box">
                        <div class="title gray">订单编号</div>
                        <div class="date">414154654646456464</div>
                    </div>
                </div>
                <div class="btn">
                    <van-button color="#f5c204" block round size="small">投诉</van-button>
                </div>
            </div>
            <div class="order-content">
                <div class="p-item">
                    <div class="title">服务需求: </div>
                    <div class="des">1辆非营运车辆上户；2辆非营运车辆临牌；1辆非营运车辆上户；2辆非营运车辆临牌；1辆非营运车辆上户；2辆非营运车辆临牌</div>
                </div>
                <div class="p-item">
                    <div class="title">订单状态: </div>
                    <div class="des">已完结</div>
                </div>
                 <div class="p-statis-info">
                    <div class="title">总费用: </div>
                    <div class="hx-price">￥350</div>
                     <div class="price spaceL">￥280</div>
                </div>
                 <div class="p-tips">
                      处理结果:   补贴用户70元
                 </div>
            </div>
        </div>
    </div>
    -->
      <div class="order-empty-box">
      <img src="@/assets/img/empty-list.png"/>
      <div class="des">您暂无违规处罚，要再接再厉哦~</div>
    
    </div>
  </div>
</template>

<script>
//import { Toast } from "vant";
export default {
  data() {
    return {};
  },
  methods: {
    goBack: function() {
      this.$router.go(-1);
    },
    goHome: function() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>

.service-box {
    margin: 5px 6px;
    display: grid;
    grid-template-columns: 100%;
   grid-template-rows: repeat(auto-fill,auto);
   grid-row-gap: 6px;
}
.service-box .service-item {
    background-color: #fff;
    border-radius: 4px;
    padding: 0px 12px;
}
.service-box .service-item .item-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
}
.service-item .order-content {
    margin-top: 10px;
    margin-bottom: 10px;
}
.order-content .p-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    font-size: 14px;
    color: #333;
    margin-top: 10px;
}
.order-content .p-statis-info {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    font-size: 14px;
    color: #333;
    margin-top: 10px;
}

.order-content .p-tips {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    font-size: 14px;
    color: #f5c204;
    margin-top: 10px;
    border-bottom: solid 3px #f5c204;
    padding-bottom: 4px;
    width: 180px;
}

.p-statis-info .hx-price {
    font-size: 14px;
    color: #333;
    text-decoration:line-through
}
.p-statis-info .price {
    font-size: 15px;
    font-weight: bold;
    color: #f5c204;
    text-decoration:line-through
}
.order-content .p-statis-info .title {
    min-width: 80px;
    max-width: 80px;
    letter-spacing: 4px;
}
.order-content .p-item .title {
    min-width: 80px;
    max-width: 80px;
   
}
.gray {
    color: #808080 !important;
}
.item-header .title-box {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    height: 24px;
    line-height: 24px;
}
.item-header .title-box .title {
    font-size: 14px;
    color: #333;
}
.item-header .title-box .date {
    font-size: 13px;
    color: #808080;
    margin-left: 12px;
}
.service-box .service-item .btn {
    width: 80px;
}


.order-empty-box {
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

}
.order-empty-box .btn {
  margin-top: 20px;
  width: 160px;
}
.order-empty-box img {
  width: 80px;
  height: 80px;
}
.order-empty-box .des {
  margin-top: 16px;
  font-size: 13px;
  color: #808080;
}
</style>
