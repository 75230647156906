export default {
    resources: {
        redirect: 'https://syz365.cn/login',
        auth: {
            wxAuthorize: 'https://open.weixin.qq.com/connect/oauth2/authorize?'
                         + 'appid=wxbaf2b2b4c928c89d'
                         + "&redirect_uri=" + encodeURIComponent('https://syz365.cn/login')
                         + "&response_type=" + 'code'
                         + "&scope=" + 'snsapi_userinfo' + "&connect_redirect=1#wechat_redirect"
                         + '&state=todo',
            wxAccessToken: 'https://api.weixin.qq.com/sns/oauth2/access_token?appid=wxbaf2b2b4c928c89d&secret=78b344f4137692d0248e7816450bf889&code={code}&grant_type=authorization_code'
        },
        service: {
            serviceCate: 'https://jsagency.cn/getStaffOrderCate',
            serviceList: 'https://jsagency.cn/getCarService',
            getStaffOrderList: 'https://jsagency.cn/getStaffOrderList',
            staffGetOrder: 'https://jsagency.cn/staffGetOrder',
            staffServiceOrder: 'https://jsagency.cn/staffServiceOrder',
            staffUpSpeed: 'https://jsagency.cn/staffUpSpeed',
            staffOrderDetail: 'https://jsagency.cn/staffOrderDetail',
            myOrderList: 'https://jsagency.cn/myOrderList',
            staffOrderEnd: 'https://jsagency.cn/staffOrderEnd',
            staffGetUserCancelOrder: 'https://jsagency.cn/staffGetUserCancelOrder',
            getContentFormPid: 'https://jsagency.cn/getContentFormPid',
              // 排行榜
            rank: 'https://jsagency.cn/api/staff/rangKing',
            // 代办奖励
            toDoRewardList: 'https://jsagency.cn/api/staff/activityList',
            // 领奖
            getReward: 'https://jsagency.cn/api/staff/getActivityMoney',
            staffFins: 'https://jsagency.cn/staffFins',
            finsOrder: 'https://jsagency.cn/finsOrder'
            //serviceCate: '/api/getStaffOrderCate',
            //serviceList: '/api/getCarService'
        },
        user: {
            register: 'https://jsagency.cn/staffRegister',
            phoneRegister: 'https://jsagency.cn/loginAsPhone',
            sendCode: 'https://jsagency.cn/sendMsg',
            staffInfo: 'https://jsagency.cn/staffInfo',
            staffInfoIndex:'https://jsagency.cn/staffInfoIndex',
            //代办员认证
            todoStaffCert: 'https://jsagency.cn/registerStaffData',
            changOrderRound: 'https://jsagency.cn/changOrderRound',
            changOrderType: 'https://jsagency.cn/changOrderType',
            upStaffHeadimg: 'https://jsagency.cn/upStaffHeadimg',
            changStatus: 'https://jsagency.cn/changStatus',

            //代办员提现
            applyCashOut: 'https://jsagency.cn/applyCashOut',
            //提现记录
            getCashOutList: 'https://jsagency.cn/getCashOutList',
            // 资质认证首页
            getStaffAuth: 'https://jsagency.cn/api/staff/getStaffServiceAll',
            // 资质认证详情
            getStaffAuthInfo: 'https://jsagency.cn/api/staff/getStaffService',
            // 资质认证接口
            updateStaffAuthInfo: 'https://jsagency.cn/api/staff/upStaffService',
            // 分销二维码
            staffShare: 'https://jsagency.cn/staffShare',
          
            /*
            register: '/api/staffRegister',
            phoneRegister: '/api/loginAsPhone',
            sendCode: '/api/sendMsg',
            staffInfo: '/api/staffInfo',
            //代办员认证
            todoStaffCert: '/api/registerStaffData'
            */
        },
        base: {
            uploadImg : 'https://jsagency.cn/uploadImg',
            getGradeArticle: "https://jsagency.cn/getGradeArticle",
            upMyIdea: "https://jsagency.cn/upMyIdea",
            block: "https://jsagency.cn/block",

        }
    }
}