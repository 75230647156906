<template>
  <div class="body-bg">
    <div class="container">
      <div class="header">
        <div class="logo">
          <img src="@/assets/img/logo.png" />
        </div>
        <div class="info">
          <div class="title">极速代办</div>
          <div class="des">专业代办平台</div>
        </div>
      </div>
      <div class="button-group">
        <van-button round block type="info" color="#F3C301" @click="login"
          >微信授权登录</van-button
        >
        <div class="kbox"></div>
        <div class="kbox"></div>
        <van-button
          round
          block
          plain
          type="info"
          color="#F3C301"
          @click="goToPhoneLogin"
          >手机快速登录</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Common from "@/api/common";
import Utils from "@/utils/utils";
import UserService from "@/api/user";
import { Toast } from '_vant@2.10.11@vant';
export default {
  data() {
    return {
      token: "",
      code: "",
      sharePId: "",
    };
  },
  async beforeMount() {
    var code = Utils.getParmCodeFromUrl(window.location.href);
    this.code = code;
    //将code传递给后端,后端获取用户信息
    if (code != null) {
      this.$cookie.set("code", code);
      //this.getToken(code);
    } else {
      window.location.href = Common.resources.auth.wxAuthorize;
    }
  },
  methods: {
    goToPhoneLogin: function() {
      setTimeout(function() {}, 1500);
      this.$router.push({
        name: "PhoneLogin",
        params: {
          token: this.$cookie.get("token"),
        },
      });
    },
    handLogin() {
      /** 
      // 重定向地址重定到当前页面，在路径获取 code

      if (this.code === "") {
        window.location.href = Common.resources.auth.wxAuthorize;
      }

      if (this.code) {
        // 存在 code 直接调用接口
        this.login(this.code);
      }
      */
      //this.$cookie.set("userName", "流乙浩");
      //this.$cookie.set("isLogin", true);
      //this.$router.push("/");
      //this.login(this.$cookie.get("code"))
    },
    /*
    async getToken(code) {
      var managerId = this.$cookie.get("managerId");
      var sharePId = this.$cookie.get("sharePId");
      const resp = await UserService.staffRegister(
        this,
        code,
        managerId,
        sharePId
      );
      if (resp.data.code == 10000) {
        this.$cookie.set("token", resp.data.data);
        this.token = this.$cookie.get("token");
      } else {
        this.token = "获取token失败";
 
      }
    },
    */
    async login() {
      var managerId = this.$cookie.get("managerId");
      var sharePId = this.$cookie.get("sharePId");
      this.managerId = managerId;
      this.sharePId = sharePId;
      const resp = await UserService.staffRegister(
        this,
        this.code,
        managerId,
        sharePId
      );
      if (resp.data.code == 10000) {
      
        this.$cookie.set("token", resp.data.data);
        this.token = this.$cookie.get("token");
        var params = {
          token: this.token,
        };
        const resp2 = await UserService.staffInfo(this, params);
        if (resp2.data.code == 10000) {
           //Toast("获取用户信息成功")
          this.$cookie.set("isLogin", true);
          this.$cookie.set("token", this.token);
          if (
            resp2.data.data.staff_phone == null ||
            resp2.data.data.staff_phone == ""
          ) {
            //跳转到首页
             this.$router.push("/");
           
          } else {
              // 设置头像
            Toast(resp2.data.msg)
            this.$router.push("/");
          }
        }
        
      } else {
        this.token = "获取token失败";
        Toast(resp.data.msg)
      }
    },
  },
};
</script>

<style scoped>
.body-bg {
  background-color: #ffffff !important;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.container .header {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.container .header .info {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-left: 20px;
}
.header .info .title {
  font-size: 20px;
  font-weight: 700;
  color: #ffcc00;
}
.header .info .des {
  font-size: 12px;
  color: #f3c301;
  letter-spacing: 2px;
}
.container .button-group {
  margin-top: 60px;
  width: 260px;
  font-size: 14px;
}
</style>
