<template>
  <div id="app" v-wechat-title="$route.meta.title">
    <router-view
      v-if="isRouterAlive"
      v-wechat-title="$route.meta.title"
    ></router-view>
  </div>
</template>

<script>
export default {
  components: {},
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isWechat: true, // 是否为微信环境（true：是；false：否）
      isRouterAlive: true,
    };
  },
  created() {
    // 检测是浏览器端还是微信端
    let ua = navigator.userAgent.toLowerCase();
    if (String(ua.match(/MicroMessenger/i)) === "micromessenger") {
      this.isWechat = true;
    } else {
      this.isWechat = false;
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function() {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>

<style lang="less">
* {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    "PingFang SC", "Microsoft YaHei", "Source Han Sans SC", "Noto Sans CJK SC",
    "WenQuanYi Micro Hei", sans-serif;
  margin: 0;
  padding: 0;
}
html {
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style: none;
  /*火狐下隐藏滚动条*/
  scrollbar-width: none;
}
/*Chrome下隐藏滚动条，溢出可以透明滚动*/
html::-webkit-scrollbar {
  width: 0px;
}

html,
body {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  color: #666;
  font-size: 12px;
  overflow-x: hidden;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #666;
}
i {
  font-style: normal;
}

img {
  margin: 0;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
  border: 0;
}
</style>
