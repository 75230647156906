<template>
  <div class="body-bg">
    <van-nav-bar
      title="个人中心"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>

    <div class="my-header">
      <div style="height: 10px"></div>
      <div class="conbox">
        <div class="avator-box">
          <div class="avator-info">
            <div class="avator" @click="toPersonInfo">
              <img :src="avator" />
            </div>
            <div class="rank" v-if="userStatus == 3">
              <img v-if="userGrade == 1" src="@/assets/img/jp.png" />
              <img v-else-if="userGrade == 2" src="@/assets/img/jp.png" />
              <img v-else-if="userGrade == 3" src="@/assets/img/jp.png" />
            </div>
          </div>
          <div class="info">
            <p class="pName">{{ userName }}</p>
            <div class="pPhone">
              <p>{{ phone }}</p>
              <van-icon
                color="#333"
                style="margin-left: 5px"
                name="edit"
                @click="toChangePhone"
              />
            </div>
          </div>
        </div>
        <!--
        <div class="info-tips">
          <div class="progress">
            <van-progress pivot-text="" color="#f2826a" :percentage="hp" />
          </div>

          距下一等级差<span class="star">{{need_ordernum}}单</span>,金额差<span class="star"
            >{{need_pricenum}}元</span>,好评数差<span class="star">{{need_evaluatenum}}个</span>
        </div>
        -->
      </div>

      <p class="rule" @click="toRule">等级规则</p>
    </div>
    <div class="hp-box">
      <div class="info-tips">
        距下一等级差<span class="star">{{ need_ordernum }}单</span>,金额差<span
          class="star"
          >{{ fmoney(need_pricenum,2) }}元</span
        >,好评数差<span class="star">{{ need_evaluatenum }}个</span>
        <div class="progress">
          <van-progress
            pivot-text=""
            color="#F5C204"
            :percentage="hp"
            stroke-width="6px"
          />
        </div>
      </div>
    </div>
    <div class="wallet-box">
      <div class="wallet-item">
        <p class="title">可提现金额(元)</p>
        <p class="money">{{ userBalance }}</p>
        <div class="button" @click="toWalletCrash">
          <img src="@/assets/img/tixian.png" />
          <p class="txt">提现</p>
        </div>
      </div>
      <div class="line"></div>
      <div class="wallet-item">
        <p class="title">邀请人数(人)</p>
        <p class="money">{{ fans }}</p>
        <div class="button" @click="toInvite">
          <img src="@/assets/img/yaoqing.png" />
          <p class="txt">邀请</p>
        </div>
      </div>
    </div>
    <div class="content-box">
      <van-cell-group :border="false">
        <van-cell is-link @click="goTodo">
          <div slot="icon" class="cell-title spaceR">
            <img src="@/assets/img/come.png" />
          </div>
          <div slot="title">
            <div class="van-cell-text">代办员认证</div>
          </div>
          <div slot="default" v-if="userStatus==3" class="font-size: 13px;">
            已认证
          </div>
          <div slot="default" v-if="userStatus==2">
            认证中
          </div>
        </van-cell>
        <!--
        <van-cell is-link @click="toBusiness">
          <div slot="icon" class="cell-title spaceR">
            <img src="@/assets/img/business.png" />
          </div>
          <div slot="title">
            <div class="van-cell-text">技能认证</div>
          </div>
        </van-cell>
        -->
        <!--
        <van-cell is-link @click="toCert">
          <div slot="icon" class="cell-title spaceR">
            <img src="@/assets/img/business.png" />
          </div>
          <div slot="title">
            <div class="van-cell-text">资质认证</div>
          </div>
        </van-cell>
        -->
        <van-cell
          title="下载名片"
          is-link
          @click="toCardDownload"
          v-if="staff_card!=''"
        >
          <div slot="icon" class="cell-title spaceR">
            <img src="@/assets/img/person-card.png" />
          </div>
        </van-cell>
        <van-cell
          title="联系我们"
          icon="/img/store.png"
          is-link
          @click="toContract"
        >
          <div slot="icon" class="cell-title spaceR">
            <img src="@/assets/img/contract.png" />
          </div>
        </van-cell>
        <van-cell title="关于我们" is-link>
          <div slot="icon" class="cell-title spaceR">
            <img src="@/assets/img/about.png" />
          </div>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="button-box">
      <van-button round block type="info" plain color="#F3C301" @click="logout"
        >退出登录</van-button
      >
    </div>
  </div>
</template>

<script>
import UserService from "@/api/user";
//import { Toast } from "vant";
export default {
  data() {
    return {
      userName: "",
      userGrade: "",
      phone: "",
      userOrderNum: "",
      userStatus: "",
      staff_card: "",
      userBalance: "",
      avator: "",
      fans: "",
      hp: 0,
      need_ordernum: 0,
      need_evaluatenum: 0,
      need_pricenum: 0,
    };
  },
  async beforeCreate() {
    //得到用户统计信息
    var token = this.$cookie.get("token");
    //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
    var params = {
      token: token,
    };
    const resp1 = await UserService.staffInfo(this, params);
    if (resp1.data.code == 10000) {
      this.userName = resp1.data.data.staff_name;
      this.userGrade = resp1.data.data.staff_grade;
      this.phone = resp1.data.data.staff_phone;
      this.userOrderNum = resp1.data.data.staff_ordernum;
      this.userStatus = resp1.data.data.status;
      //可提现金额
      this.userBalance = resp1.data.data.staff_balance;
      this.avator = resp1.data.data.staff_img;
      this.$cookie.set("staffImg", resp1.data.data.staff_img);
      this.fans = resp1.data.data.staff_fans;
      this.hp = resp1.data.data.hp * 100;
      this.need_ordernum = resp1.data.data.need_ordernum;
      this.need_evaluatenum = resp1.data.data.need_evaluatenum;
      this.need_pricenum = resp1.data.data.need_pricernum;
      this.staff_card = 'https://jsagency.cn/'+resp1.data.data.staff_card;
    }
  },
  methods: {
    toChangePhone: function() {
      this.$router.push("/person/changePhone");
    },
    toPersonInfo: function() {
      this.$router.push({
        name: "UpdatePerson",
        params: {
          avator: this.avator,
        },
      });
    },
    goBack: function() {
      this.$router.go(-1);
    },
    goHome: function() {
      this.$router.push("/");
    },
    toRule: function() {
      this.$router.push("/person/rule");
    },
    toWalletCrash: function() {
      if (this.userBalance < 1) {
        //Toast("金额太小无法提现");
        //return;
      }
      this.$router.push("/person/wallet");
    },
    goTodo: function() {
      //this.$router.push("/person/auth");
      this.$router.push("/person/authDetail");
    },
    toCert: function() {
      this.$router.push("/person/auth");
    },
    toBusiness: function() {
      this.$router.push("/person/business");
    },
    toContract: function() {
      this.$router.push("/person/contract");
    },
    toCardDownload: function() {
      this.$router.push("/person/card");
    },
    toInvite: function() {
      this.$router.push("/person/invite");
    },
    logout: function() {
      this.$cookie.set("isLogin", false);
      this.$cookie.set("token", "");
      this.$$cookie.set("code","");
      this.$router.push("/login");
    },
    fmoney(s, n) {
      n = n > 0 && n <= 20 ? n : 2;
      s = parseFloat((s + "").replace(/[^\d\\.-]/g, "")).toFixed(n) + "";
      var l = s
          .split(".")[0]
          .split("")
          .reverse(),
        r = s.split(".")[1];
      var t = "";
      for (var i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
      }
      return (
        t
          .split("")
          .reverse()
          .join("") +
        "." +
        r
      );
    },
  },
};
</script>

<style scoped>
.rule {
  position: absolute;
  font-size: 13px;
  color: #fff;
  top: 70px;
  right: 20px;
}
.container {
  overflow: hidden;
}
.star {
  color: #fb6354;
  font-weight: 800;
  display: inline-block;
}
.my-header {
  width: 100%;
  height: 240px;
  background: url("~@/assets/img/person-header-bg.png") no-repeat;
  background-size: 100% 100%;
}

.my-header .conbox {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.conbox .rank {
  margin-top: -30px;
  margin-left: auto;
  margin-right: auto;
  width: 72px;
  height: 21px;
}
.conbox .info-tips {
  margin-top: 10px;
  font-size: 9px;
}
.info-tips .progress {
  margin: 10px auto;
}
.rank img {
  width: 100%;
  height: 100%;
}
.conbox .avator-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.conbox .avator {
  margin: 0 0;
  width: 100px;
  height: 100px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.conbox .avator img {
  width: 60px;
  height: 60px;
  border-radius: 60px;
}

img {
  width: 16px;
  height: 16px;
}
.conbox .info {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.conbox .info .pName {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.conbox .info .pPhone {
  margin-top: 4px;
  padding-right: 10px;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.hp-box {
  margin: -80px 12px 0px 12px;
  padding: 6px 0;
  height: 60px;
  background: #fff;
  box-shadow: 0px 0px 7px 1px rgba(223, 223, 223, 0.45);
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.wallet-box {
  margin: 14px 12px 0px 12px;
  padding: 6px 0;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 0px 7px 1px rgba(223, 223, 223, 0.45);
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.wallet-box .line {
  width: 1px;
  height: 30px;
  background: #e6e6e6;
}
.wallet-box .wallet-item {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.wallet-item .title {
  font-size: 10px;
  font-family: PingFang SC;
  color: #b3b3b3;
}
.wallet-item .money {
  font-size: 18px;
  font-weight: 800;
  color: #fb6354;
  margin: 4px 0;
}
.wallet-item .button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 25px;
  background: #f5c204;
  border-radius: 12px;
}
.wallet-item .button:active {
  background: #f3cc40;
}
.wallet-item .button .txt {
  font-size: 13px;
  color: #333;
  margin-left: 4px;
}
.content-box {
  position: relative;
  margin: 15px 12px 0px 12px;
  background-color: #ffffff;
  padding: 4px 4px;
  border-radius: 4px;
  box-shadow: 0px 0px 7px 1px rgba(223, 223, 223, 0.45);
}

.button-box {
  margin: 30px 20px;
}

.cell-title {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
}
</style>

https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx12f16d479a7d3698&redirect_uri=https%3A%2F%2Fwww.baidu.com&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect
