<template>
  <div class="body-bg">
    <van-nav-bar
      title="奖励"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <img src="@/assets/img/index-o.png" style="width: 16px;height:16px" />
      </template>
    </van-nav-bar>

    <div class="order-list-box">
      <van-tabs
        sticky
        active="0"
        color="#F3C301"
        title-active-color="#F3C301"
        title-inactive-color="#B3B3B3"
        line-width="40rpx"
        line-height="4rpx"
        @change="changeType"
      >
        <van-tab
          tab-class="custom-tab-class"
          tab-active-class="custom-tab-class"
          title="代办奖励"
          name="1"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="loadData(1)"
          >
            <ul>
              <li
                class="order-item-container"
                v-for="(item, index) in rewardList"
                :key="index"
              >
                <div class="order-item-box">
                  <div class="content">
                    <div class="p-item">
                      <img class="coupon-icon" src="@/assets/img/coupon.png" />
                      <div class="title">{{ item.title }}</div>
                    </div>
                    <div class="p-item">
                      <div class="coupon-icon"></div>
                      <div class="title gray">{{ item.speed }}</div>
                    </div>
                  </div>
                  <div class="divided"></div>
                  <div class="coupon-box" @click="getReward(item)">
                    <div class="money">{{ fmoney(item.activity_money,0) }}元</div>
                    <div class="badage">
                      <img
                        v-if="item.this_activity_ok_status == 1"
                        src="@/assets/img/rewarded.png"
                      />
                      <img v-if="item.this_activity_ok_status == 2" src="@/assets/img/unreward.png" />
                      <img v-if="item.this_activity_ok_status == 3" src="@/assets/img/已失效.png" />
                       <img v-if="item.this_activity_ok_status == 0" src="@/assets/img/unfinished.png" />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </van-list>
          <div class="order-empty-box" v-if="rewardList.length == 0">
            <img src="@/assets/img/empty-list.png" />
            <div class="des">您暂无可领取的代办奖励，去做业务吧!~</div>
            <div class="btn">
              <van-button
                @click="toOrderHall"
                style="border-raduis: 8px;"
                block
                round
                color="#f3c301"
                size="small"
                >去接单大厅</van-button
              >
            </div>
          </div>
        </van-tab>
        <van-tab title="邀请奖励" name="2">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="loadData(2)"
          >
            <ul>
              <li
                class="order-item-container"
                v-for="(item, index) in rewardList"
                :key="index"
              >
                <div class="order-item-box">
                  <div class="content">
                    <div class="p-item">
                      <img class="coupon-icon" src="@/assets/img/coupon.png" />
                      <div class="title">{{ item.title }}</div>
                    </div>
                    <div class="p-item">
                      <div class="coupon-icon"></div>
                      <div class="title gray">{{ item.speed }}</div>
                    </div>
                  </div>
                  <div class="divided"></div>
                  <div class="coupon-box" @click="getReward(item)">
                    <div class="money">{{ fmoney(item.activity_money,0) }}元</div>
                    <div class="badage">
                      <img
                        v-if="item.this_activity_ok_status == 1"
                        src="@/assets/img/rewarded.png"
                      />
                      <img v-if="item.this_activity_ok_status == 2" src="@/assets/img/unreward.png" />
                      <img v-if="item.this_activity_ok_status == 3" src="@/assets/img/已失效.png" />
                      <img v-if="item.this_activity_ok_status == 0" src="@/assets/img/unfinished.png" />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </van-list>
          <div class="order-empty-box" v-if="rewardList.length == 0">
            <img src="@/assets/img/empty-list.png" />
            <div class="des">
              您暂无可领取邀请奖励，去邀请代办行业好友入驻一起做业务吧！
            </div>
            <div class="btn">
              <van-button
                @click="toShare"
                style="border-raduis: 8px;"
                block
                color="#f3c301"
                round
                size="small"
                >去分享</van-button
              >
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import UserService from "@/api/user";
import Service from "@/api/service";
import { Toast } from "vant";

export default {
  data() {
    return {
      loading: false,
      finished: false,
      userInfo: null,
      rewardList: [],
      type: 0,
      page: 1,
    };
  },

  async beforeCreate() {
    //得到用户统计信息
    //var token = this.$cookie.get("token");
    var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
    var params = {
      token: token,
    };
    const resp1 = await UserService.staffInfo(this, params);
    if (resp1.data.code == 10000) {
      this.userInfo = resp1.data.data;
    }
  },

  methods: {
    loadData: function(type) {
      this.type = type;
      this.getRewardList();
      this.page++;
    },
    async getReward(item) {
      var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
      var params = {
        token: token,
        activityId: item.id,

      };
      if(item.this_activity_ok_status==0||item.this_activity_ok_status==3||item.this_activity_ok_status==2){
        return
      }
      const resp1 = await Service.getReward(this, params);
      if (resp1.data.code == 10000) {
        console.log(resp1)
        this.getRewardList();
      }else{
        Toast(resp1.data.msg)
      }
    },
    toOrderHall: function() {
      this.$router.push({
        name: "OrderHall",
        params: {},
      });
    },
    toShare: function() {
      this.$router.push("/person/invite");
    },
    async getRewardList() {
      var params2 = {
        //token: this.$cookie.get("token"),
        token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
        page: this.page,
        type: this.type
      };
      const resp2 = await Service.toDoRewardList(this, params2);
      if (resp2.data.code == 10000) {
        var list = resp2.data.data;
        this.loading = false;
        if (list == null || list.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }
        // 将新数据与老数据进行合并
        this.rewardList = this.rewardList.concat(list);
      }
    },
    changeType(name) {
      console.log(name);
      this.type = name;
      this.rewardList = [];
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.loadData(name);
    },
    goPerson: function() {
      this.$router.push("/person");
    },
    goTodo: function() {
      this.$router.push("/person/auth");
    },

    goBack: function() {
      this.$router.go(-1);
    },
    goHome: function() {
      this.$router.push("/");
    },
    fmoney(s, n) {
      n = n > 0 && n <= 20 ? n : 1;
      s = parseFloat((s + "").replace(/[^\d\\.-]/g, "")).toFixed(n) + "";
      var l = s
          .split(".")[0]
          .split("")
          .reverse(),
        r = s.split(".")[1];
      var t = "";
      for (var i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
      }
      return (
        t
          .split("")
          .reverse()
          .join("") +
        "." +
        r
      );
    },
  },
};
</script>

<style scoped>
.van-tab {
  font-size: 13px !important;
  background: #fafafa;
}
.custom-tab-class {
  background: #fafafa !important;
}
.van-tab .van-tab__text {
  font-size: 13px !important;
}
.van-tab--active .van-tab__text {
  font-size: 14px !important;
}
.van-tab--active {
  background: #fafafa !important;
}
.order-box {
  margin: 10px 6px 0 6px;
  box-shadow: 0px 0px 7px 2px rgba(119, 119, 119, 0.05);
  border-radius: 4px;
}
.order-list-box {
  margin: 5px 0px;
  background: #fafafa;
}

.order-list-box li {
  margin-top: 6px;
  background-color: #ffffff;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 4px;
}
.order-list-box li:first-child {
  margin-top: 6px;
  background-color: #ffffff;
  border-radius: 4px;
}
.order-item-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  padding: 20px 20px;
  border-radius: 4px;
}
.order-item-box .content {
  width: 70%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.order-item-box .content .p-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.content .p-item .coupon-icon {
  width: 36px;
  height: 36px;
}

.content .p-item .title {
  padding: 4px 4px;
  font-size: 14px;
  color: #000;
}
.order-item-box .coupon-box {
  width: 29%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 80px;
}
.coupon-box .money {
  font-size: 24px;
  color: #f5c204;
  font-weight: bold;
}
.coupon-box .badage {
  position: absolute;
  right: -12px;
  top: -16px;
}
.coupon-box .badage img {
  width: 48px;
  height: 48px;
}
.order-item-box .divided {
  width: 1px;
  height: 48px;
  margin: 0px 16px;
  background: #eeeeee;
}

.van-cell-group {
  border-radius: 6px !important;
}
.van-cell-raduis {
  border-radius: 6px !important;
}
.van-cell-raduis-top {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
.van-cell-raduis-bottom {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.order-empty-box {
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.order-empty-box .btn {
  margin-top: 20px;
  width: 160px;
}
.order-empty-box img {
  width: 80px;
  height: 80px;
}
.order-empty-box .des {
  margin-top: 16px;
  width: 70%;
  text-align: center;
  font-size: 13px;
  color: #808080;
}
</style>
